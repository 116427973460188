export var SearchList = [
  { labe: '物料编号', code: 'itemNumber', type: 'input' },
  { labe: '物料描述', code: 'materialDescription', type: 'input' },
  {
    labe: '需求日期',
    code: 'demandDate',
    type: 'daterange'
  },
  {
    labe: '实际交付日期',
    code: 'actualDeliveryDate',
    type: 'daterange'
  },
  { labe: '采购单编号', code: 'purchaseOrderNumber', type: 'input' },
  { labe: '采购员', code: 'buyer', type: 'input', placeholder: '采购员编码或名称' },
  {
    labe: '需求类型',
    code: 'bednr',
    type: 'input',
    placeholder: '类型'
  },{
    labe: 'BU',
    code: 'lgort',
    type: 'input',
    placeholder: 'BU'
  },
]
export var SearchData = {
  itemNumber: '',
  materialDescription: '',
  demandDate: '',
  actualDeliveryDate: '',
  purchaseOrderNumber: '',
  bednr: ''
}

export var tableField = [
  { label: '物料编号', code: 'bidCode', type: 'input', width: '' },
  { label: '物料描述', code: 'itemName', type: 'input', width: '' },
  { label: '需求数量/单位', code: 'bidCode', type: 'input', width: '' },
  { label: '需求日期', code: 'itemName', type: 'input', width: '' },
  { label: '交付数量/单位', code: 'itemName', type: 'input', width: '' },
  { label: '交付日期', code: 'itemName', type: 'input', width: '' },
  { label: '状态', code: 'itemName', type: 'input', width: '' }
]

export var tableData = []

export var menudata = [{
  label: '导出',
  action: 'draftContract',
  id: 1
}
]
